<template>
  <div id="rounds-bg" style="min-height: 100vh">
    <div id="rounds-content">
      <a
        class="round-permissions-header-back-page"
        @click="$router.push(`/round/${roundId}`)"
      >
        <font-awesome-icon
          icon="fa-solid fa-arrow-left"
          class="round-permissions-header-back-page-icon"
        />
        <span class="round-permissions-header-back-page-text">{{
          $t("round_access_permissions")
        }}</span>
      </a>
      <div class="rounds-header">
        <div>
          <h2 class="rounds-sub-title">
            {{ $t("round_access_permissions_subtitle") }}
          </h2>
        </div>
      </div>
      <!-- rounds section of filter -->
      <div class="rounds-filter">
        <h3 class="rounds-filters-title">
          {{ $t("filters") }}
        </h3>
        <v-form
          @submit.stop.prevent="getRoundsByPage"
          class="rounds-filters-items-flex"
        >
          <div>
            <span class="rounds-filters-label">{{ $t("name") }}</span>
            <v-text-field
              data-test="Round:Permission:FilterName"
              class="closing-form-input-text"
              color="primary"
              dense
              outlined
              v-model="filters.NameFilter"
            >
            </v-text-field>
          </div>
          <div>
            <span class="rounds-filters-label">{{ $t("order_by") }}</span>
            <v-autocomplete
              :items="permissionsOrderFields"
              :item-text="translateField"
              item-value="value"
              v-model="filters.Order"
              color="primary"
              dense
              class="rounds-filters-auto-complete"
              :label="$t('select_label')"
              outlined
            >
            </v-autocomplete>
          </div>
          <div class="round-permission-check">
            <v-checkbox
              v-model="filters.AllowedOnly"
              class="field"
              color="primary"
              outlined
              dense
            >
              <template v-slot:label>
                <span style="color: var(--dark)">{{
                  $t("round_show_users_with_permission")
                }}</span>
              </template>
            </v-checkbox>
          </div>
          <div class="rounds-filters-item-button-to-filter-box">
            <NewDxaButton
              data-test="Round:Permission:FilterSubmit"
              @btnFunction="getFilteredPermissions"
              :loading="loading"
              type="submit"
              :outline="true"
              :title="$tc('filter_kpi', 1)"
            />
          </div>
        </v-form>
      </div>
      <!-- rounds table -->
      <div v-if="loading" class="box-loading">
        <v-progress-circular
          indeterminate
          size="35"
          color="primary"
        ></v-progress-circular>
      </div>
      <div class="rounds-table-content" v-else>
        <v-tabs
          v-model="currentTab"
          @change="tabChanged"
          class="rounds-table-tabs"
        >
          <v-tab>{{ $tc("investor", 2) }}</v-tab>
          <v-tab>{{ $tc("partner", 2) }}</v-tab>
        </v-tabs>
        <v-simple-table>
          <thead class="rounds-table-header rounds-table-head">
            <th>
              {{ $t("name") }}
              <v-icon
                small
                color="primary"
                @click="headerClick(orderFilterEnum.NAME)"
                >{{
                  orderByMode === orderByModeEnum.ASC
                    ? "mdi-arrow-up"
                    : "mdi-arrow-down"
                }}</v-icon
              >
            </th>
            <th v-if="currentTab === tabPermissionEnum.INVESTOR">
              {{ $t("email") }}
              <v-icon
                small
                color="primary"
                @click="headerClick(orderFilterEnum.EMAIL)"
                >{{
                  orderByMode === orderByModeEnum.ASC
                    ? "mdi-arrow-up"
                    : "mdi-arrow-down"
                }}</v-icon
              >
            </th>
            <th>
              {{ $t("briefing_permission") }}
            </th>
          </thead>
          <tbody>
            <tr v-for="(item, i) in permissions" :key="i">
              <td class="text-center">
                <span
                  v-if="item.fullName"
                  class="rounds-table-item rounds-table-item-round-number"
                  >{{ item.fullName }}
                </span>
                <p v-else>-</p>
              </td>
              <td class="text-center" v-if="item.email">
                <span
                  v-if="currentTab === tabPermissionEnum.INVESTOR"
                  class="rounds-table-item rounds-table-item-company-name"
                  >{{ item.email }}
                </span>
                <span v-else>-</span>
              </td>
              <td
                class="text-start"
                style="display: flex; justify-content: center"
              >
                <v-checkbox
                  data-test="Round:Permission:CheckBox"
                  @change="(e) => toggleUserPermission(e, item)"
                  v-model="item.briefingPermission"
                  class="field"
                  color="primary"
                  outlined
                  dense
                >
                </v-checkbox>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-pagination
          color="primary"
          @input="changePage"
          v-model="page"
          :length="pageCount"
        >
        </v-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import NewDxaButton from "@/components/dxa/Button/NewDxaButton";
import { mapState, mapMutations } from "vuex";

const OrderFilterEnum = {
  EMAIL: 0,
  NAME: 1,
  BRIEFING_PERMISSION: 2,
};

const tabPermissionEnum = {
  INVESTOR: 0,
  PARTNER: 1,
};

const OrderByModeEnum = {
  ASC: 0,
  DESC: 1,
};

export default {
  name: "RoundPermissions",
  components: {
    NewDxaButton,
  },
  computed: {
    ...mapState(["round", "currentRoundId"]),
  },
  data() {
    return {
      apiService: new ApiService(),
      loading: false,
      orderFilterEnum: OrderFilterEnum,
      orderByModeEnum: OrderByModeEnum,
      orderByMode: OrderByModeEnum.ASC,
      permissions: [],
      currentTab: 0,
      roundId: null,
      tabPermissionEnum,
      page: 1,
      pageCount: 1,
      permissionsOrderFields: [
        {
          name: "name",
          value: OrderFilterEnum.NAME,
        },
        {
          name: "email",
          value: OrderFilterEnum.EMAIL,
        },
      ],
      filters: {
        Page: 1,
        Order: -1,
        AllowedOnly: false,
        NameFilter: null,
      },
    };
  },
  async created() {
    let routeRoundId = Number(this.$route.params.roundId);

    // pegando o id da rodada pela rota
    this.roundId = routeRoundId;

    // preciso setar o roundId na store para caso
    // o usuário use o botão de voltar do browser
    // eu ter o roundID pra preencher os campos
    this.setCurrenRoundId(routeRoundId);

    await this.getInvestorsPermissions();
  },
  methods: {
    ...mapMutations({
      setCurrenRoundId: "SET_CURRENT_ROUND_ID",
    }),
    async headerClick(col) {
      this.orderBy = col;
      if (this.orderByMode == 0) {
        this.orderByMode = 1;
      } else {
        this.orderByMode = 0;
      }
      this.filteredMethodByTabPermissions();
    },
    checkUserType() {
      if (this.currentTab === tabPermissionEnum.INVESTOR) {
        return "investor";
      } else {
        return "partner";
      }
    },
    toggleUserPermission(value, user) {
      const url = `investmentround/${this.checkUserType()}-permission/change`;
      let payload = { InvestmentRoundId: this.roundId, Allowed: value };

      if (this.currentTab === tabPermissionEnum.INVESTOR) {
        payload.UserId = user.id;
      } else {
        payload.PartnerB2bId = user.id;
      }

      this.apiService
        .postRequest(url, payload)
        .then((result) => {
          this.$toast.success(this.$t("round_permission_changed_successfully"));
        })
        .catch((err) => {
          this.loading = false;
          this.$toast.error(this.$t("error_occoured"));
          return err;
        });
    },
    async getFilteredPermissions() {
      this.page = 1;
      await this.filteredMethodByTabPermissions();
    },
    translateField(item) {
      return this.$t(item.name);
    },
    async filteredMethodByTabPermissions() {
      if (this.currentTab === tabPermissionEnum.INVESTOR) {
        await this.getInvestorsPermissions();
      } else {
        await this.getPartnerPermissions();
      }
    },
    async changePage() {
      this.filteredMethodByTabPermissions();
    },
    async getInvestorsPermissions() {
      this.loading = true;
      let params = { ...this.filters };
      params.Page = this.page;

      if (!params.NameFilter) {
        params.NameFilter = null;
      }

      if (this.orderByMode != null) {
        params.Order = this.orderByMode;
      }

      await this.apiService
        .postRequest("investmentround/investor-view/list/filter", {
          ...params,
          InvestmentRoundId: this.roundId,
        })
        .then((result) => {
          const { totalPages, totalItems, currentPage } = result.content.pager;

          this.permissions = result.content.users;
          this.pageCount = totalPages;
          this.page = currentPage;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$toast.error(this.$t("error_occoured"));
          return err;
        });
    },
    async getPartnerPermissions() {
      let params = { ...this.filters };
      params.Page = this.page;

      if (!params.NameFilter) {
        params.NameFilter = null;
      }

      if (this.orderByMode != null) {
        params.Order = this.orderByMode;
      }

      this.loading = true;
      await this.apiService
        .postRequest("investmentround/partner-view/list/filter", {
          ...params,
          InvestmentRoundId: this.roundId,
        })
        .then((result) => {
          const { totalPages, totalItems, currentPage } = result.content.pager;
          this.permissions = result.content.users;
          this.pageCount = totalPages;
          this.page = currentPage;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$toast.error(this.$t("error_occoured"));
          return err;
        });
    },
    tabChanged(e) {
      if (e === tabPermissionEnum.INVESTOR) {
        this.getInvestorsPermissions();
      } else {
        this.getPartnerPermissions();
      }
    },
  },
};
</script>
<style scoped>
.round-permissions-header-back-page {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 24px;
}

.round-permissions-header-back-page-icon {
  width: 22px;
  height: 22px;
  color: var(--primary);
}

.round-permissions-header-back-page-text {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

/* general styles */
#rounds-bg {
  position: absolute;
  top: -75px;
  width: 100%;
  min-height: 100vh !important;
  background-color: var(--white);
}

#rounds-content {
  /* 75px precisa ser fixo para das a distancia do AppBar */
  margin-top: calc(75px + 20px);
  width: 100%;
  padding: 0 60px;
  background-color: var(--white);
  padding-bottom: 40px;
}

.rounds-table-content {
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.rounds-table-tabs {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* header styles */
.rounds-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
}

.rounds-title {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 35px;
  line-height: 44px;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 8px;
}

.rounds-sub-title {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: #535353;
}

/* styles box filter */
.rounds-filter {
  background-color: var(--white);
  border-radius: 12px;
  padding: 24px;
  min-height: 160px;
}

.rounds-filters-title {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 24px;
}

.rounds-filters-label {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: var(--dark);
  display: block;
  margin-bottom: 8px;
}

.rounds-filters-auto-complete {
  min-width: 210px;
}

.rounds-filters-date {
  box-sizing: border-box;
  border-radius: 4px;
  min-width: 124px;
}

.rounds-filters-items-flex {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
  padding-left: 20px;
}

.rounds-date-flex-items {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

/* para compensar o tamanho do label que ele não tem */
.rounds-filters-item-button-to-filter-box {
  margin-top: 26px;
  max-width: 86px;
}

.rounds-date-item-space-letter {
  display: block;
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: #9c9c9c;
  margin-top: 12px;
}

.rounds-filter-expansion-panel-content {
  padding-left: 40px;
}

.rounds-filter {
  margin-bottom: 40px;
}

/* styles table */
.rounds-table-header {
  background: var(--white);
  height: 60px;
}

.rounds-table-head > th {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.rounds-table-link {
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: max-content;
  text-decoration: none;
}

.rounds-table-link > span {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: var(--primary);
  display: block;
}

.rounds-table-item {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.rounds-table-item-round-number {
  display: block;
  font-weight: 700;
}

.rounds-table-item-company-name {
  font-weight: 700;
  color: var(--dark);
}

.box-loading {
  display: grid;
  place-items: center;
}

.round-permission-check {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.closing-form-input-text {
  font-family: "Source Sans Pro";
  font-weight: 400;
  color: var(--dark);
}

@media only screen and (max-width: 1140px) {
  .rounds-filters-items-flex {
    grid-template-columns: repeat(2, 1fr);
    gap: 0 40px;
  }

  .rounds-filters-auto-complete {
    min-width: auto;
  }

  .rounds-filters-date {
    min-width: auto;
  }
}

@media only screen and (max-width: 769px) {
  #rounds-bg {
    min-height: 100vh !important;
  }

  .rounds-filter-expansion-panel-content {
    padding-left: 0px;
  }

  .rounds-filters-items-flex {
    grid-template-columns: repeat(1, 1fr);
    gap: 0 40px;
    padding-left: 0px;
  }

  .rounds-filters-item-button-to-filter-box {
    margin-left: 0px;
  }

  .rounds-header {
    flex-direction: column;
    margin-bottom: 30px;
  }

  .rounds-date-flex-items {
    flex-direction: column;
    gap: 0px;
  }

  .rounds-date-item-space-letter {
    margin-top: 0px;
    text-align: center;
    align-self: center;
    margin-bottom: 8px;
  }

  .rounds-sub-title {
    margin-bottom: 20px;
  }
}
</style>
